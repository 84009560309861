<template>
    <div id="available-stock" class="produse-stock">
        <div v-if="soldout == 1 || (outlet && offer.quantity === 0)">
            <span class="red-stock"
                ><img src="@/assets/product/red_stock.svg" alt="" /> stoc epuizat</span
            >
        </div>
        <div v-else-if="offer.price_value > 0" class="produse-stock">
            <span v-if="offer.quantity > 3" class="green-stock"
                ><img src="@/assets/product/green_stock.svg" alt="" /> in stoc</span
            >
            <span v-if="offer.quantity == 3" class="green-stock"
                ><img src="@/assets/product/green_stock.svg" alt="" /> ultimele 3
                produse</span
            >
            <span v-if="offer.quantity == 2" class="green-stock"
                ><img src="@/assets/product/green_stock.svg" alt="" /> ultimele 2
                produse</span
            >
            <span v-if="offer.quantity == 1" class="green-stock"
                ><img src="@/assets/product/green_stock.svg" alt="" /> ultimul
                produs</span
            >
            <span v-if="offer.quantity == 0" class="orange-stock"
                ><img src="@/assets/product/orange_stock.svg" alt="" /> disponibil pe
                comanda</span
            >
        </div>
        <div v-else-if="offer.quantity > 0" class="produse-stock">
            <span class="green-stock"
                ><img src="@/assets/product/green_stock.svg" alt="" /> in stoc</span
            >
        </div>
        <div v-else class="produse-stock">
            <span class="orange-stock"
                ><img src="@/assets/product/orange_stock.svg" alt="" /> disponibil pe
                comanda</span
            >
        </div>
    </div>
</template>

<script>
export default {
    name: "ProductStock",
    props: {
        offer: Object,
        soldout: Number,
        outlet: {
            type: Boolean,
            default: false,
        },
    },
};
</script>

<style scoped>
.produse-stock span {
    font-family: Montserrat Medium;
    font-size: 12px;
    vertical-align: middle;
}

.produse-stock img {
    margin: 0 3px 1px 0;
}
.produse-stock .green-stock {
    color: #59c528;
}
.produse-stock .orange-stock {
    color: #fca613;
}
.produse-stock .red-stock {
    color: #fc0707;
}
</style>
