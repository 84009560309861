<template>
  <div
    v-if="currentIcon"
    class="clasa-ener-ribon clasa-ener-ribon-image"
    :style="ribonBackground"
  ></div>
</template>

<script>
export default {
  props: ["energyclass"],

  data() {
    return {
      currentIcon: null,
      icons: [
        {
          class: "A",
          icon: "a_arrow.png",
        },
        {
          class: "A+",
          icon: "a+_arrow.png",
        },
        {
          class: "A++",
          icon: "a++_arrow.png",
        },
        {
          class: "B",
          icon: "b_arrow.png",
        },
        {
          class: "C",
          icon: "c_arrow.png",
        },
        {
          class: "D",
          icon: "d_arrow.png",
        },
      ],
    };
  },

  computed: {
    ribonBackground() {
      return (
        "background-image: url(" +
        require(`@/components/product_page/ribons/energyclass/${this.currentIcon.icon}`) +
        ");"
      );
    },
  },

  created() {
    // console.log(this.energyclass);
    if (this.energyclass) {
      //   console.log(this.energyclass.value);
      this.currentIcon = this.icons.find((icon) => {
        // console.log(icon.class);
        return icon.class === this.energyclass;
      });
      //   console.log(this.currentIcon);
    }
  },
};
</script>

<style scoped>
.clasa-ener-ribon {
  top: 10px;
  right: 0;
  position: absolute;
  /*float: left;*/
  /*background-color: #454546;*/
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
  height: 20px;
  width: 40px;
  /*display: table-cell;*/
  vertical-align: middle;
}

@media (max-width: 576px) {
  .product-page-detailed .clasa-ener-ribon {
    width: 60px;
    height: 30px;
    z-index: 1;
  }

  #listproducts .clasa-ener-ribon,
  .list-products-page .clasa-ener-ribon {
    top: 8.2px;
    height: 12.2px;
    width: 25.1px;
  }
}
</style>
