<template>
  <div v-if="ribon.icon" class="hquality-ribon" :style="ribonBackground"></div>
</template>

<script>
export default {
  props: ["ribon"],
  computed: {
    ribonBackground() {
      return "background-image: url(" + this.ribon.icon + ");";
    },
  },
};
</script>

<style scoped>
.hquality-ribon {
  background-repeat: no-repeat;
  background-position: center;
  height: 40px;
  background-size: 40px;
  /* background-image: url(~@/assets/images/product/high_quality.png); */
  width: 40px;
  vertical-align: middle;
  margin-bottom: 8px;
}

@media (max-width: 576px) {
  .product-page-detailed .hquality-ribon {
    width: 50px;
    height: 50px;
    background-size: 50px;
  }

  #listproducts .hquality-ribon,
  .list-products-page .hquality-ribon {
    height: 25px;
    width: 25px;
    background-size: 25px;
    margin-bottom: 2px;
  }
}
</style>
