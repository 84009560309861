<template>
  <EnergyClass :energyclass="energy_class" />

  <div class="ribons" rib="">
    <WarrantyRibon :warranty="warranty" :warrantytext="false" />

    <LabelRibon v-for="(ribon, index) in ribons" :key="index" :ribon="ribon" />
  </div>
</template>

<script>
import EnergyClass from "@/components/product_page/ribons/energyclass/EnergyClass.vue";
import LabelRibon from "@/components/product_page/ribons/label/LabelRibon.vue";
import WarrantyRibon from "./ribons/warranty/WarrantyRibon.vue";

export default {
  name: "Ribons",
  props: ["ribons", "product"],

  data() {
    return {
      warranty: null,
      energy_class: null,
    };
  },

  components: {
    EnergyClass,
    LabelRibon,
    WarrantyRibon,
  },

  computed: {
    // product() {
    //   return this.$store.getters['shop/product'];
    // }
  },

  mounted() {
    // console.log(this.product);
  },
  created() {
    // Aci trb facute verificarile astea pt ca de ex pe pagina cu sticla termo da erori js
    if (this.product !== undefined && this.product?.property !== undefined) {
      let garantie = this.product?.property?.filter((elem) => {

       if(this.product.code.includes('OUTLET') && elem.code == "garantie"){ 
          return false; 
        }
        return elem.code == "garantie";
      });

      if (garantie[0] !== undefined) {
        this.warranty = garantie[0].description;
      }

      let clasaenergetica = this.product?.property?.filter((elem) => {
        return elem.code == "clasaenergetica";
      });

      if (clasaenergetica[0] !== undefined) {
        this.energy_class = clasaenergetica[0].value;
      }
    }
  },
};
</script>

<style scoped>
@media (max-width: 768px) {
  .ribons {
    display: flex;
  }

  .product-page-detailed .ribons {
    /* bottom: -70px; */
  }
  .hoch-carousel .ribons {
    bottom: -70px;
  }
}

@media (max-width: 576px) {
  .product-page-detailed .livrare-ribon {
    width: 50px;
    height: 50px;
    background-size: 50px;
  }

  #listproducts .ribons,
  .list-products-page .ribons {
    left: 2px;
  }
}
</style>
