<template>
  <div v-if="warranty" class="garantie-ribon">
    <span class="garantie-ribon-text">
      <span class="number-warranty-years">{{ warranty }}</span
      ><br />
      <span class="text-warranty-years">ANI</span>
      <span v-if="warrantytext" class="text-warranty">Garanție</span>
    </span>
  </div>
</template>

<script>
export default {
  props: {
    warrantytext: {
      type: Boolean,
      default: true,
    },
    warranty: {
      type: String,
      default: null,
    },
  },
  //   mounted() {
  //     console.log(this.warranty);
  //   },
};
</script>

<style scoped>
.garantie-ribon {
  /*float: left;*/
  left: 48px;
  height: 40px;
  width: 40px;
  /*background-color: #454546;*/
  display: table;
  color: #000;
  font-family: Raleway Medium;
  background-size: 40px;
  background-image: url(~@/assets/images/product/garantie-bg-small.png);
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 5px;
}

span.garantie-ribon-text {
  display: table-cell;
  vertical-align: middle;
  line-height: 9px;
  padding-top: 4px;
}

span.number-warranty-years {
  font-size: 20.24px;
  display: block;
}

.product-page-detailed span.number-warranty-years {
  font-size: 40px;
  display: block;
}

.product-page-detailed span.garantie-ribon-text:before {
  content: "Garanție";
  color: #000;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  font-family: Raleway Light;
  font-size: 13px;
}

span.text-warranty-years {
  font-size: 8.29px;
  display: block;
}

@media (max-width: 576px) {
  .product-page-detailed .livrare-ribon,
  .product-page-detailed .garantie-ribon {
    width: 50px;
    height: 50px;
    background-size: 50px;
  }

  #listproducts .garantie-ribon,
  .list-products-page .garantie-ribon {
    height: 25px;
    width: 25px;
    background-size: 25px;
    margin-bottom: 0;
  }

  #listproducts span.garantie-ribon-text,
  .list-products-page span.garantie-ribon-text {
    line-height: 5px;
    padding-top: 0;
  }

  #listproducts span.number-warranty-years,
  .list-products-page span.number-warranty-years {
    font-size: 9.24px;
  }

  #listproducts span.text-warranty-years,
  .list-products-page span.text-warranty-years {
    font-size: 6.23px;
  }
}
</style>
