<template>
  <div
    itemprop="mainEntity"
    itemscope
    :itemtype="product?.offers[0]?.price_value > 0 && product.sold_out == 0 ? 'http://schema.org/Product' : null"
    class="product-entity"
  >
    <!-- gift -->
    <!-- <div class="list-prod-overlay gift-list"> -->
    <!-- <a href="<?php echo  $this->config->item('gift_link') ?>" rel="summer_prom"> -->
    <!-- <img src="/assets/img/sys/gift_yellow_prod.png" alt="" /> -->
    <!-- </a> -->
    <!-- </div> -->

    <div class="item thumb">
      <router-link :to="{ name: 'productpage', params: { slug: product.slug } }">
        <!-- promotie -->
        <div v-if="isPromotion(product)" class="wpr-pr-general">
          <div class="lichidare-de-stoc">
            <img itemprop="image" src="@/assets/product/Sale.png" alt="" />
          </div>
        </div>
        <!-- end promotie -->
        <div
          class="produs-image"
        >
          <img
            v-if="product.thumbnail"
            :src="product.thumbnail"
            itemprop="image"
            alt="image"
            title="Image"
            loading="lazy"
          />
          <img
            v-else
            src="@/assets/images/product/default-thumb.jpg"
            itemprop="image"
            alt="image"
            title="Image"
            loading="lazy"
          />
          <product-ribons :ribons="productLabels" :product="product" />
        </div>

        <div class="produs-item-content">
          <div itemprop="name" class="produse-name">
            <template v-if="product.name">{{ product.name }}</template>
            <skeleton-box v-else :min-width="20" :max-width="50" />
          </div>

        <ProductStock v-if="!product.hide_stock" :offer="product.offers[0]" :soldout="product.sold_out" :outlet="product.has_related_parent" />

          <div v-if="product.preview_text" itemprop="description" class="produse-description-c">
            <span v-html="product.preview_text"></span>
          </div>

          <div
            itemprop="offers"
            itemscope
            itemtype="http://schema.org/Offer"
            class="produse-price"
          >
            <div v-if="isPromotion(product) && isInStock(product) && !isConfigurator(product)" class="old-price">
              <span v-if="product.offers[0]">
                {{ product.offers[0].old_price.split(".")[0] }}
                <span itemprop="priceCurrency" content="RON"> lei</span>
              </span>
            </div>

            <div class="pret" :class="{ 'promotion-color': isPromotion(product) }">
              <span v-if="isCustomPrice(product) || isConfigurator(product)"> Calculeaza pret! </span>
              <span v-else-if="!isInStock(product)"> Stoc epuizat </span>
              <span v-else-if="product.offers[0].price_value === 0"> Cere oferta! </span>
              <span v-else itemprop="price" :content="product.offers[0].price">
                {{ product.offers[0].price.split(".")[0] }}
                <span itemprop="priceCurrency" content="RON"> lei</span>
              </span>
            </div>
          </div>

          <!-- <div class="produse-price"> -->
          <!-- <div class="pret"> -->
          <!-- Stoc epuizat -->
          <!-- </div> -->
          <!-- <link href="http://schema.org/OutOfStock" /> -->
          <!-- <meta content="<?php echo $value->pret; ?>"> -->
          <!-- </div> -->
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import ProductRibons from "@/components/product_page/ProductRibons.vue";
import { shopMixin } from "@/mixins/shopMixin";
import SkeletonBox from "@/components/blocks/SkeletonBox.vue";
import ProductStock from "@/components/product_page/ProductStock.vue";

export default {
  name: "ProductCard",
  props: ["product"],
  components: {
    "product-ribons": ProductRibons,
    SkeletonBox,
    ProductStock,
  },
  mixins: [shopMixin],
  computed: {
    productLabels() {
      let labels = [];
      let ecoDesign = false;
      if(this.product?.property?.length > 0) {
        this.product?.property.forEach((elem) => {
          if(elem.value == "Eco Design") {
            ecoDesign = true;
          }
        });
      }

      if(this.product?.category_labels?.length > 0) {
        this.product.category_labels.forEach((element) => {
          if(element.code == "pro") {
            labels.push(element);
          }
        });
      }

      // Push all product labels to the array
      if(this.product?.labels?.length > 0) {
        this.product.labels.forEach((element) => {
          if(ecoDesign && element.code == "transport-gratuit") return;
          if(!ecoDesign && element.code == "eco-design") return;
          labels.push(element);
        });
      }
      
      return labels;
    },
  },
  methods: {
    isCustomPrice(product) {
      if (product.layout_property.length) {
        let customPrice = product.layout_property.filter((elem) => {
          return elem.code === "product-price";
        });
        if (customPrice.length) {
          return true;
        }
      }
      return false;
    },
    isConfigurator(product) {
      if (product.layout_property.length) {
        let layout = product.layout_property.filter((elem) => {
          return elem.code === "layout";
        });
        if (layout.length && layout[0].value == "custom-hoch") {
          return true;
        }
      }
      return false;
    },
  }
};
</script>

<style scoped>
div.produse-name {
  height: 52px;
  font-size: 16px;
}

.produse-description-full-width {
  object-fit: contain;
  font-family: Montserrat Light;
  font-size: 14px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #121111;
  padding: 0;
  height: 70px;
}

@media (max-width: 576px) {
  div.produse-name {
    font-size: 12px;
    padding-top: 12px;
    height: 44px;
  }

  div.produse-description-c {
    font-size: 11px;
    line-height: 12px;
    letter-spacing: 0;
    height: 41px;
    -webkit-line-clamp: 3;
  }

  .pret,
  .old-price {
    font-size: 12px;
    line-height: normal;
  }

  #listproducts .lichidare-de-stoc,
  .list-products-page .lichidare-de-stoc {
    width: 70px;
    font-size: unset;
  }

  #listproducts .wpr-pr-general .lichidare-de-stoc img,
  .list-products-page .wpr-pr-general .lichidare-de-stoc img {
    width: 20px;
  }
}
</style>
